import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import GlobalLayout from "components/GlobalLayout";
import Nav from "components/Nav";
import MobileNav from "components/MobileNav";
import Footer from "components/Footer";
import ContactBar from "components/ContactBar";

const GroupMenu = () => {
  const data = useStaticQuery(
    graphql`
      {
        wp {
          menu(idType: NAME, id: "Group Menu") {
            id
            menuItems {
              nodes {
                id
                label
                path
              }
            }
          }
          themeGeneralSettings {
            globalOptions {
              contactBannerMessage
              instagram
              linkedin
              twitter
              address
              phone
              email
            }
          }
        }
      }
    `
  );
  return data.wp;
};

const Layout = ({
  title,
  page,
  nav = true,
  contact = true,
  footer = true,
  children,
  logoLink,
  bespokeHome,
}) => {
  const data = GroupMenu();
  return (
    <GlobalLayout title={title}>
      {nav && (
        <Nav
          theme={`group`}
          menu={data.menu}
          logoLink={logoLink || `/group`}
          instagram={data.themeGeneralSettings.globalOptions.instagram}
          twitter={data.themeGeneralSettings.globalOptions.twitter}
          linkedin={data.themeGeneralSettings.globalOptions.linkedin}
        />
      )}
      {nav && (
        <MobileNav
          theme={`group`}
          menu={data.menu}
          logoLink={logoLink || `/group`}
        />
      )}

      {children}
      {contact && (
        <ContactBar
          theme={`stormdust`}
          link={`/bespoke/contact`}
          contactBannerMessage={
            data.themeGeneralSettings.globalOptions.contactBannerMessage
          }
        />
      )}
      {footer && (
        <Footer
          theme={`group`}
          page={page}
          menu={data.menu}
          email={data.themeGeneralSettings.globalOptions.email}
          phone={data.themeGeneralSettings.globalOptions.phone}
          instagram={data.themeGeneralSettings.globalOptions.instagram}
          twitter={data.themeGeneralSettings.globalOptions.twitter}
          linkedin={data.themeGeneralSettings.globalOptions.linkedin}
          address={data.themeGeneralSettings.globalOptions.address}
        />
      )}
    </GlobalLayout>
  );
};

export default Layout;
