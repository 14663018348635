import React from "react";

const LogoGlyph = ({ className }) => {
  return (
    <svg
      viewBox="0 0 57.803 69.684"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M42.66 34.842v33.3a1.524 1.524 0 01-1.539 1.539H17.236a1.539 1.539 0 010-3.078h22.346v-4.491H9.665a1.539 1.539 0 010-3.078h29.917V54.6H1.539A1.524 1.524 0 010 53.061v-36.44a1.539 1.539 0 113.078 0v34.9h4.494V9.049a1.539 1.539 0 013.078 0v42.475h4.494V1.539A1.524 1.524 0 0116.682 0h23.884a1.524 1.524 0 011.539 1.539 1.524 1.524 0 01-1.539 1.539H18.221v4.494h29.917a1.539 1.539 0 010 3.078H18.221v4.494h38.043a1.539 1.539 0 010 3.078H18.221v33.3h21.422v-16.68a1.524 1.524 0 011.539-1.539 1.473 1.473 0 011.478 1.539zm6.033-1.539a1.524 1.524 0 00-1.539 1.539v25.792a1.539 1.539 0 003.078 0V34.842a1.564 1.564 0 00-1.54-1.542zm7.51 0a1.524 1.524 0 00-1.539 1.539v18.221a1.539 1.539 0 003.078 0V34.842A1.524 1.524 0 0056.2 33.3z" />
    </svg>
  );
};
export default LogoGlyph;
