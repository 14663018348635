import * as React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import LogoGlyph from "../components/LogoGlyph";
import LogoBig from "../components/LogoBig";
import Image from "../components/Image";
import GroupSwiper from "../components/GroupSwiper";

export const query = graphql`
  {
    wp {
      page(id: "/landing/", idType: URI) {
        id
        title
        pageLanding {
          backgroundImage {
            id
            ...MediaItem
          }
          bespokeTagline
          commercialTagline
          tagline
        }
      }
    }
  }
`;

// markup
const IndexPage = ({ data }) => {
  const page = data.wp.page;
  return (
    <Layout title={page.title} nav={false} contact={false} footer={false}>
      <main>
        <div className="min-h-screen grid place-items-center">
          <div className="absolute z-0 top-0 left-0 flex w-full h-full">
            <div className="absolute inset-0 z-10 bg-gradient-to-b from-black/10 via-black/25 to-black/40"></div>
            <Image
              image={page.pageLanding.backgroundImage}
              className="inset-0 z-0 object-cover"
            />
          </div>
          <div className="absolute top-6 md:top-16 left-1/2 transform translate-x-[-50%] flex flex-col items-center w-full md:w-auto">
            <LogoBig className="fill-white max-w-[150px] md:max-w-[260px]" />
            <div className="mt-4 uppercase tracking-wider text-white text-center w-full">
              {page.pageLanding.tagline}
            </div>
          </div>
          <GroupSwiper
            bespokeTagline={page.pageLanding.bespokeTagline}
            commercialTagline={page.pageLanding.commercialTagline}
          />
          <div className="hidden lg:flex relative w-full">
            <Link
              to="commercial"
              className="flex flex-col items-center py-8 px-6 xl:p-8 space-y-6 flex-1 bg-everglade/90 transition hover:bg-everglade"
            >
              <div>
                <FiChevronLeft className="text-3xl text-white" />
              </div>
              <div className="uppercase text-center text-2xl xl:text-3xl tracking-[24px] xl:tracking-[30px] text-cinnabar">
                Commercial
              </div>
              <div className="uppercase tracking-wider text-white text-center">
                {page.pageLanding.commercialTagline}
              </div>
            </Link>
            <Link
              to="group"
              className="py-8 px-16 bg-tiber flex flex-col justify-center items-center transition hover:bg-tiber/90"
            >
              <LogoGlyph className="w-[4.5rem] fill-white" />
            </Link>
            <Link
              to="bespoke"
              className="flex flex-col items-center py-8 px-6 xl:p-8 space-y-6 flex-1 bg-firefly/90 transition hover:bg-firefly"
            >
              <div>
                <FiChevronRight className="text-3xl text-white" />
              </div>
              <div className="uppercase text-center text-2xl xl:text-3xl tracking-[24px] xl:tracking-[30px] text-white">
                Bespoke Living
              </div>
              <div className="uppercase tracking-wider text-white text-center">
                {page.pageLanding.bespokeTagline}
              </div>
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
