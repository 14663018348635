import React from "react";

const SwipeIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 47.4 51.9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44 4.5H30.5c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1H44c.6 0 1.1-.5 1.1-1.1.1-.6-.4-1.1-1.1-1.1zm.8.4L40.3.4c-.4-.4-1.2-.4-1.6 0s-.4 1.2 0 1.6l3.7 3.7-3.7 3.7c-.4.4-.4 1.2 0 1.6s1.2.4 1.6 0l4.5-4.5c.5-.5.5-1.2 0-1.6zm-30.1-.4H1.1C.5 4.5 0 5 0 5.6c0 .6.5 1.1 1.1 1.1h13.5c.6 0 1.1-.5 1.1-1.1.1-.6-.4-1.1-1-1.1zm-12 1.1l3.7-3.7c.4-.4.4-1.2 0-1.6S5.2-.1 4.8.3L.3 4.9c-.4.4-.4 1.2 0 1.6L4.8 11c.2.2.5.3.8.3.3 0 .6-.1.8-.3.4-.4.4-1.2 0-1.6L2.7 5.6zm40.2 17c-.9 0-1.8.3-2.5.8-.8-2.4-3.4-3.6-5.8-2.8-.4.1-.7.3-1 .5-.6-1.8-2.3-3-4.3-3-.8 0-1.6.2-2.3.6v-7.4c0-2.5-2-4.5-4.5-4.5s-4.5 2-4.5 4.5v19.2l-3.9-2.9c-2.3-1.7-5.6-1.5-7.7.5-1.3 1.3-1.3 3.5 0 4.8L22.5 49c1.9 1.9 4.5 3 7.2 3H35c6.9 0 12.4-5.6 12.4-12.4V27.1c0-2.5-2-4.5-4.5-4.5zm2.3 16.9c0 5.6-4.6 10.2-10.2 10.2h-5.3c-2.1 0-4.1-.8-5.6-2.3L8 31.3c-.4-.4-.4-1.2 0-1.6 1.3-1.3 3.3-1.4 4.7-.3l5.7 4.3c.5.4 1.2.3 1.6-.2.1-.2.2-.4.2-.7V11.3c0-1.2 1-2.3 2.3-2.3 1.2 0 2.3 1 2.3 2.3v16.9c0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1v-5.6c0-1.2 1-2.3 2.3-2.3 1.2 0 2.3 1 2.3 2.3v5.6c0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1v-3.4c0-1.2 1-2.3 2.3-2.3 1.2 0 2.3 1 2.3 2.3v3.4c0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1v-1.1c0-1.2 1-2.3 2.3-2.3 1.2 0 2.3 1 2.3 2.3v12.4z"
        fill="#fff"
      />
    </svg>
  );
};

export default SwipeIcon;
