import React from "react";
import { Link } from "gatsby";
import SwiperCore, { Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import LogoGlyph from "components/LogoGlyph";
import SwipeIcon from "components/SwipeIcon";

SwiperCore.use([Pagination]);

const GroupSwiper = ({ bespokeTagline, commercialTagline }) => {
  return (
    <div className="relative z-10 lg:hidden w-screen text-white font-bold">
      <Swiper
        className=""
        spaceBetween={0}
        pagination={true}
        slidesPerView={1}
        loop={false}
        onSlideChange={(e) => {}}
      >
        <SwiperSlide className="relative py-32 h-[auto!important]">
          <Link
            to="commercial"
            className="h-full flex flex-col items-center p-6 md:p-8 space-y-6 flex-1 bg-everglade/90"
          >
            <div>
              <FiChevronLeft className="text-3xl text-white" />
            </div>
            <div className="uppercase text-xl tracking-[20px] text-cinnabar">
              Commercial
            </div>
            <div className="uppercase tracking-wider text-sm text-white text-center">
              {commercialTagline}
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="relative py-32 h-[auto!important]">
          <Link
            to="group"
            className="h-full py-8 px-16 bg-tiber flex flex-col justify-between items-center text-white"
          >
            <LogoGlyph className="w-16 md:w-[4.5rem] mb-4 fill-white" />
            <div className="uppercase tracking-wider text-sm">Group</div>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="relative py-32 h-[auto!important]">
          <Link
            to="bespoke"
            className="h-full flex flex-col items-center p-6 md:p-8 space-y-6 flex-1 bg-firefly/90 text-white"
          >
            <div>
              <FiChevronRight className="text-3xl" />
            </div>
            <div className="uppercase text-xl tracking-[20px]">Bespoke</div>
            <div className="uppercase tracking-wider text-sm text-center">
              {bespokeTagline}
            </div>
          </Link>
        </SwiperSlide>
      </Swiper>
      <SwipeIcon className={`mt-8 w-12 mx-auto`} />
    </div>
  );
};

export default GroupSwiper;
